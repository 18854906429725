import React from 'react'
// eslint-disable-next-line import/named
import { graphql, PageProps } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import {
  ApplicationPrivacyPolicyData,
  ArticleHeader,
  BlocksRender,
  PageRichText,
  Section,
  SEO,
} from '@wunderdogsw/finbb-dms-theme'

import Layout from '../components/layout/layout'
import * as blockComponents from '../blocks'
import useFiApplicationPrivacyPolicy from '../hooks/use-fi-application-privacy-policy'
import useEnApplicationPrivacyPolicy from '../hooks/use-en-application-privacy-policy'

type ContentfulLink = {
  id: string
  text: string
  url: string
}

type ContentfulPage = {
  id: string
  fields: {
    route: string
  }
  menuTitle: string
}

type ContentfulYawaveButton = {
  id: string
  source: {
    source: string
  }
}

type DataProps = {
  contentfulApplication: {
    siteLogo: {
      resize: {
        src: string
      }
    }
    siteLogoInverse: {
      resize: {
        src: string
      }
    }
    navbarLinks: ({ __typename: string } & (
      | {
          id: string
          title: string
          pages: (ContentfulLink | ContentfulPage | ContentfulYawaveButton)[]
        }
      | ContentfulLink
      | ContentfulPage
      | ContentfulYawaveButton
    ))[]
  }

  contentfulArticlePage: {
    blocksAfter: {
      __typename: string
      [key: string]: any
    }[]
    blocksBefore: {
      __typename: string
      [key: string]: any
    }[]
    body: {
      raw: string
      references: any[]
    }
    fields: {
      route: string
    }
    fullTitle: string
    metaDescription?: { metaDescription: string }
    metaImage?: {
      file: {
        url: string
      }
      gatsbyImageData: IGatsbyImageData
    }
    metaTitle: string
    parentPage: {
      fields: {
        route: string
      }
      menuTitle: string
    }
    publishedAt: string
  }
}

type PageContextProps = {
  locale: 'en-US' | 'fi-FI'
}

const ArticlePage: React.FC<PageProps<DataProps, PageContextProps>> = ({
  data,
  pageContext,
}) => {
  const locale = pageContext.locale

  let applicationData: ApplicationPrivacyPolicyData
  if (locale === 'fi-FI') {
    applicationData = useFiApplicationPrivacyPolicy()
  } else {
    applicationData = useEnApplicationPrivacyPolicy()
  }

  const { contentfulArticlePage: page } = data
  const {
    metaTitle,
    metaDescription,
    metaImage,
    fullTitle,
    publishedAt,
    parentPage,
    blocksBefore,
    blocksAfter,
    body,
  } = page
  const { route } = page.fields

  return (
    <Layout locale={locale} navbarInverse={true}>
      <SEO
        title={metaTitle}
        description={metaDescription && metaDescription.metaDescription}
        image={metaImage && metaImage.file.url}
        path={route}
      />
      <article>
        <ArticleHeader
          title={fullTitle}
          publishedAt={publishedAt}
          gatsbyImageData={metaImage?.gatsbyImageData}
          path={route}
          parent={parentPage}
        />
        {blocksBefore && (
          <BlocksRender
            applicationData={applicationData}
            components={blockComponents}
            locale={locale}
          >
            {blocksBefore}
          </BlocksRender>
        )}
        <Section.Body>
          <PageRichText>{body}</PageRichText>
        </Section.Body>
        {blocksAfter && (
          <BlocksRender
            applicationData={applicationData}
            components={blockComponents}
            locale={locale}
          >
            {blocksAfter}
          </BlocksRender>
        )}
      </article>
    </Layout>
  )
}

export default ArticlePage

export const query = graphql`
  query ($slug: String!, $locale: String!) {
    contentfulApplication(
      title: { eq: "FINBB" }
      node_locale: { eq: $locale }
    ) {
      navbarLinks {
        ... on Node {
          __typename
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulPage {
            ...Page
          }
          ... on ContentfulPageGroup {
            id
            title
            pages {
              ... on Node {
                ... on ContentfulLink {
                  ...Link
                }
                ... on ContentfulPage {
                  ...Page
                }
                ... on ContentfulYawaveButton {
                  ...YawaveButton
                }
              }
            }
          }
          ... on ContentfulYawaveButton {
            ...YawaveButton
          }
        }
      }
      privacyPolicy {
        __typename
        ... on Node {
          ... on ContentfulArticlePage {
            id
            fields {
              route
            }
            menuTitle
          }
          ... on ContentfulLink {
            id
            text
            url
          }
          ... on ContentfulPage {
            id
            fields {
              route
            }
            menuTitle
          }
        }
      }
      siteLogo {
        resize(height: 96) {
          src
        }
      }
      siteLogoInverse {
        resize(height: 96) {
          src
        }
      }
    }
    contentfulArticlePage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      blocksAfter {
        __typename
        ...Blocks
      }
      blocksBefore {
        __typename
        ...Blocks
      }
      body {
        raw
        references {
          __typename
          ...RichTextReferences
        }
      }
      fields {
        route
      }
      fullTitle
      metaDescription {
        metaDescription
      }
      metaImage {
        file {
          url
        }
        gatsbyImageData(width: 2560)
      }
      metaTitle
      parentPage {
        fields {
          route
        }
        menuTitle
      }
      publishedAt(formatString: "MMM D, YYYY")
    }
  }
`
